import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Intro from "../components/intro"
import Apresentacao from "../components/apresentacao"
import Slider from "../components/clinicaSlider"
import Corpo from "../components/clinicaCorpoClinico"
import Footer from "../components/footer"

import HeaderMobile from "../components/headerMobile"
import ApresentacaoMobile from "../components/apresentacaoMobile"
import SliderMobile from "../components/clinicaSliderMobile"
import CorpoMobile from "../components/clinicaCorpoClinicoMobile"
import CallNow from "../components/callnow"
import FooterMobile from "../components/footerMobile"

const ClinicaPage = ({data}) => (
  <Layout>
    <SEO title="Clínica" />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile header={data.globalJson.header}/>
        <Intro data={data.clinicaJson.intro} blackBlue={true} mobile={true}/>
        <ApresentacaoMobile data={data.clinicaJson.apresentacao} />
        <SliderMobile data={data.clinicaJson.slider}/>
        <CorpoMobile data={data.clinicaJson.corpo} team={data.globalJson.team} teamAssist={data.globalJson.teamAssist}/>
        <CallNow />
        <FooterMobile data={data.globalJson.footer}/>
      </>
      ) : (
      <>
        <Header header={data.globalJson.header}/>
        <Intro data={data.clinicaJson.intro} blackBlue={true}/>
        <Apresentacao data={data.clinicaJson.apresentacao} />
        <Slider data={data.clinicaJson.slider}/>
        <Corpo data={data.clinicaJson.corpo} team={data.globalJson.team} teamAssist={data.globalJson.teamAssist}/>
        <Footer data={data.globalJson.footer} />
      </>)
    }
  </Layout>
)

export default ClinicaPage

export const Json = graphql`
  query clinica {
    globalJson{
      header{
        menu{
          title
          link
        }
        menuselect{
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      footer{
        logo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
          txt
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social
        {
          face
          insta
          tube
        }
      }
      team{
        title
        name
        position
        omd
        specialty
        courses
        photo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 800) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        icon{
          childImageSharp {
            fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bio
      }
      teamAssist{
        name
        position
        photo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 800) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        icon{
          childImageSharp {
            fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    clinicaJson {
      intro{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
      }
      apresentacao{
        subtitle
        title
        text
      }
      slider{
        content{
          img{
            childImageSharp {
              fluid(quality: 70, maxWidth: 1600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          imgSmall{
            childImageSharp {
              fluid(quality: 70, maxWidth: 800) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          title
          desc
        }
      }
      corpo{
        subtitle
        sub2
        title
        cursos
        especialidade
      }
    }
  }
`