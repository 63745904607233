import React, { useState, useCallback } from "react"
import { CSSTransition } from "react-transition-group"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"
import { Button } from "./buttons"

import setaD from "../images/svg/setaBigD.svg"
import setaF from "../images/svg/setaD.svg"
import setaE from "../images/svg/setaBigE.svg"

const Slider = ({data}) => {
  const [btnE, setBtnE] = useState(false);
  const [btnD, setBtnD] = useState(false);
  const [slide, setSlide] = useState(0);
  const length = data.content.length;

  const changeSlide = useCallback((n, length) => {
    if(slide + n > length-1){n=-length+1}
    if(slide + n < 0){n=length-1}
    setSlide(slide + n);
  }, [slide])

  const slideTemplate = (data, cName) => {
    return(
      <div className={"slideT " + cName}>
        <Img fluid={data.imgSmall.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain"}} />
        <h5 className={font.nexaLight}>{data.title}</h5>
      </div>
    )
  }
  const bigTemplate = (data, cName) => {
    return(
      <div className={"bigT " + cName}>
        <Img fluid={data.img.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain"}} />
        <div className="bigTxt">
          <h3 className={font.nexaLight}>{data.title}</h3>
          <p className={font.nexaRegularItalic}>{data.desc}</p>
          <div className="btns">
            <Button className="prev" onClick={()=>changeSlide(-1, length)} onMouseEnter={()=>{setBtnE(true)}} onMouseLeave={()=>{setBtnE(false)}}>
              <CSSTransition in={btnE} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={setaF} alt="seta prev" className="reverse"/>
              </CSSTransition>
              <CSSTransition in={!btnE} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={setaE} alt="seta prev"/>
              </CSSTransition>
            </Button>
            <Button className="next" onClick={()=>changeSlide(+1, length)} onMouseEnter={()=>{setBtnD(true)}} onMouseLeave={()=>{setBtnD(false)}}>
              <CSSTransition in={btnD} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={setaF} alt="seta prev"/>
              </CSSTransition>
              <CSSTransition in={!btnD} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={setaD} alt="seta next"/>
              </CSSTransition>
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return(
    <StyledContainer id="clinicaSlider">
      <div className="slider">
        <div className="size"><Img fluid={data.content[0].img.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain"}}/></div>
        {data.content.map((dataSlide, i)=>(
          <div className="slide" key={i} style={{opacity: slide===i ? "1" : "0", zIndex: slide===i ? "3" : "1"}}>
            {data.content[i-2] ? slideTemplate(data.content[i-2], "slide1") : data.content[i-1] ? slideTemplate(data.content[length-1], "slide1") : slideTemplate(data.content[length-2], "slide1")}
            {data.content[i-1] ? slideTemplate(data.content[i-1], "slide2") : slideTemplate(data.content[length-1], "slide2")}
            {bigTemplate(dataSlide, "slide3")}
            {data.content[i+1] ? slideTemplate(data.content[i+1], "slide4") : slideTemplate(data.content[0], "slide4")}
          </div>
        ))}
      </div>
    </StyledContainer>
  )
}


export default Slider

const StyledContainer = styled.div`
  overflow: hidden;
  padding-bottom: 5rem;
  .slider{
    position: relative;
    .size{width: 65%;visibility: hidden;}
    .slide{
      position: absolute;
      top: 0;
      left: -7vw;
      transition: all 0.4s;
      height: 100%;
      width: 113vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .slideT{
        position: relative;
        height: 50%;
        width: 14.5vw;
        h5{position: absolute; top: 15%; left: 50%; transform: translateX(-50%); letter-spacing: 0.1em; color: #02374e; width: 80%; text-align: center;}
      }
      .bigT{
        position: relative;
        width: 50vw;
        .bigTxt{
          position: absolute;
          width: 100%;
          h3{color: #17d4e3; text-align: center; margin: 0.4em auto; letter-spacing: 0.2em;}
          p{width: 12vw; text-align: center; margin: auto; letter-spacing: 0.1em;}
          .btns{
            display: flex;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            top: 2rem;
          }
        }
      }
    }
  }
  .prev, .next{
    width: 2.5rem;
    height: 2.5rem;
    img{width: 100%;}
  }
  .reverse{
    transform: rotate(180deg);
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    left: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`