import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Mapa = () => (
  <StyledContainer>
    <h1 className={font.financierMediumItalic}>Ligue já!</h1>
    <div className="phoneFlex">
      <a href="tel:+351 234 315 739" className="card"><h4 className={font.nexaHeavy}>+351 234 315 739</h4><small>«Chamada para a rede fixa nacional»</small></a>
      <a href="tel:+351 961 559 810" className="card"><h4 className={font.nexaHeavy}>+351 961 559 810</h4><small>«Chamada para a rede móvel nacional»</small></a>
      <a href="tel:+351 961 295 211" className="card"><h4 className={font.nexaHeavy}>+351 961 295 211</h4><small>«Chamada para a rede móvel nacional»</small></a>
    </div>
  </StyledContainer>
)

export default Mapa

const StyledContainer = styled.div`
  h1{color: #e6e6e6; width: 100%; margin: 3rem 0; text-align: center;}
  margin: 120px 0;
  .phoneFlex{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    .card{
      width: fit-content;
      text-align: center;
      padding: 3em;
      border-radius: 13px;
      box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.16);
      color: #3b3b3b;
      margin: 0 3em;
    }
  }
  @media only screen and (max-width: 1190px){
    h1{width: 100%; margin: 1em 0; text-align: center;}
    margin: 60px 0;
    .phoneFlex{
      flex-direction: column;
      width: 80%;
      .card{
        padding: 2.5em;
        margin: 0 auto 3em;
      }
    }
  }
`