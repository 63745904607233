import React, { useState, useCallback } from "react"
import { CSSTransition } from "react-transition-group"
import SliderReact from "react-slick"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"
import { Button } from "./buttons"

import setaE from "../images/svg/Seta_Esquerda1.svg"
import setaEF from "../images/svg/Seta_Esquerda2.svg"

import setaD from "../images/svg/Seta_Direita1.svg"
import setaDF from "../images/svg/Seta_Direita2.svg"

import seta from "../images/svg/setaD.svg"
import setaHover from "../images/svg/setaBigD.svg"

const Corpo = ({data, team, teamAssist}) => {
  const [setaFill, setSetaFill] = useState(false);
  const [slide, setSlide] = useState(0);
  const [slideAssist, setSlideAssist] = useState(0);
  const length = team.length;
  const lengthAssist = teamAssist.length;

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Button
        className={className  + " slick-btns"}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={setaD} alt="seta"/>
        <img src={setaDF} alt="seta"/>
      </Button>
    );
  }
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Button
        className={className + " slick-btns"}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={setaE} alt="seta"/>
        <img src={setaEF} alt="seta"/>
      </Button>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const changeSlide = useCallback((n, select) => {
    if(!select){
      if(slide + n > length-1){n=-length+1}
      if(slide + n < 0){n=length-1}
      setSlide(slide + n);
    }
    else{setSlide(n);}
  }, [slide, length])

  const changeSlideAssist = useCallback((n, select) => {
    if(!select){
      if(slideAssist + n > lengthAssist-1){n=-lengthAssist+1}
      if(slideAssist + n < 0){n=lengthAssist-1}
      setSlideAssist(slideAssist + n);
    }
    else{setSlideAssist(n);}
  }, [slideAssist, lengthAssist])

  function fade(enter, i){
    let el = document.querySelectorAll(".iconWrapper");
    if(enter==="enter"){
      el.forEach(element => {
        if(!element.classList.contains("iconWrapper".concat(i))){
          element.style.opacity = "0.6";
        }
      });
    }
    else{
      el.forEach(element => {
        element.style.opacity = "1";
      });
    }
  }

  const [filter, setFilter] = useState(0);

  return(
    <StyledContainer id="corpo-clinico">
      <h5 className={font.nexaBold + " title"}>{data.title}</h5>
      <h1 className={font.financierMediumItalic + " subtitle"}>{data.subtitle}<br/>{data.sub2}</h1>
      <div className="filter">
        <Button regular className={font.nexaRegular} style={{color: filter===0 && "#17d4e3", textDecoration: filter===0 && "underline"}} onClick={() => setFilter(0)}>Corpo Clínico</Button>
        <Button regular className={font.nexaRegular} style={{color: filter===1 && "#17d4e3", textDecoration: filter===1 && "underline"}} onClick={() => setFilter(1)}>Assistentes</Button>
      </div>
      {filter===0 ? 
        <div className="slider">
          {team.map((dataSlide, i)=>(
            <div className="slide" key={i} style={{opacity: slide===i ? "1" : "0", zIndex: slide===i ? "3" : "1"}}>
              <div className="grey">
                <div className="top">
                  <h4 className={font.nexaBold}>{dataSlide.title}</h4>
                  <h4 className={font.nexaBold}>{dataSlide.name}</h4>
                  <p className={font.nexaRegular}>{dataSlide.position}</p>
                  {dataSlide.omd!=="" ? 
                    <p className={font.nexaRegular}>{dataSlide.omd}</p>
                    : 
                    <p className={font.nexaRegular}>Fisioterapeuta</p>
                  }
                </div>
                <div className="bottom">
                  {dataSlide.specialty[0]!=="" && <p className={font.nexaLight}>{data.especialidade}</p>}
                  {dataSlide.specialty.map((dataTeam, i)=>(
                    <p className={font.nexaLight} key={i}>{dataTeam}</p>
                  ))}
                </div>
              </div>
              <div className="bio">
                <p className={font.nexaRegular}>{dataSlide.bio}</p>
              </div>
              <div className="profileImg">
                {dataSlide.photo && <Img fluid={dataSlide.photo.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain", objectPosition: "center center"}} />}
              </div>
            </div>
          ))}
          <Button className="btn" onClick={()=>changeSlide(+1)} onMouseEnter={()=>{setSetaFill(true)}} onMouseLeave={()=>{setSetaFill(false)}}>
            <CSSTransition in={setaFill} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={seta} alt="seta"/>
            </CSSTransition>
            <CSSTransition in={!setaFill} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={setaHover} alt="seta"/>
            </CSSTransition>
          </Button>

          <div className="sliderSelect">
            <div className="selector">
              <SliderReact {...settings}>
                {team.map((icon, i)=>(
                  <button className={"iconWrapper iconWrapper" + i} key={"teamMember" + i} onMouseEnter={()=>{fade("enter", i)}} onMouseLeave={()=>{fade("leave", i)}}>
                    <button className={"icon icon" + i} onClick={()=>changeSlide(i, true)} style={{top: slide===i && "35%"}}>
                      <Img fluid={icon.icon.childImageSharp.fluid} style={{width: `100%`, margin: `auto`}} imgStyle={{objectFit: "contain"}} />
                    </button>
                    <p className={font.financierMediumItalic + " iconName"} style={{opacity: slide===i && "1"}}>{icon.name}</p>
                  </button>
                ))}
              </SliderReact>
            </div>
          </div>
        </div>
      :
        <div className="slider">
          {teamAssist.map((dataSlide, i)=>(
            <div className="slide" key={i} style={{opacity: slideAssist===i ? "1" : "0", zIndex: slideAssist===i ? "3" : "1"}}>
              <div className="grey">
                <div className="top">
                  <h4 className={font.nexaBold}>{dataSlide.name}</h4>
                  <p className={font.nexaRegular}>{dataSlide.position}</p>
                </div>
              </div>
              <div className="bio"/>
              <div className="profileImg">
                {dataSlide.photo && <Img fluid={dataSlide.photo.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain", objectPosition: "center center"}} />}
              </div>
            </div>
          ))}
          <Button className="btn" onClick={()=>changeSlideAssist(+1)} onMouseEnter={()=>{setSetaFill(true)}} onMouseLeave={()=>{setSetaFill(false)}}>
            <CSSTransition in={setaFill} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={seta} alt="seta"/>
            </CSSTransition>
            <CSSTransition in={!setaFill} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={setaHover} alt="seta"/>
            </CSSTransition>
          </Button>

          <div className="sliderSelect">
            <div className="selector">
              <SliderReact {...settings}>
                {teamAssist.map((icon, i)=>(
                  <button className={"iconWrapper iconWrapper" + i} key={"teamMember" + i} onMouseEnter={()=>{fade("enter", i)}} onMouseLeave={()=>{fade("leave", i)}}>
                    <button className={"icon icon" + i} onClick={()=>changeSlideAssist(i, true)} style={{top: slideAssist===i && "35%"}}>
                      <Img fluid={icon.icon.childImageSharp.fluid} style={{width: `100%`, margin: `auto`}} imgStyle={{objectFit: "contain"}} />
                    </button>
                    <p className={font.financierMediumItalic + " iconName"} style={{opacity: slideAssist===i && "1"}}>{icon.name}</p>
                  </button>
                ))}
              </SliderReact>
            </div>
          </div>
        </div>
      }
    </StyledContainer>
  )
}


export default Corpo

const StyledContainer = styled.div`
  position: relative;
  color: #717171;
  margin-bottom: 10rem;
  .title{text-transform: uppercase; margin-left: 15vw;}
  .subtitle{color: #e6e6e6; margin: 3rem 0 1em 15vw;}
  .filter{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 150px;
    padding: 0 7%;
    p{
      text-transform: uppercase;
      user-select: none;
      cursor: pointer;
    }
  }
  .slider{
    width: 100%;
    height: 450px;
    position: relative;
    .slide{
      position: absolute;
      display: flex;
      transition: all 1s;
      width: 100%;
      height: 100%;
      .grey{
        width: 45%;
        background-color: #e6e6e6;
        padding: 4rem 9vw 4rem 15vw;
        h4{letter-spacing: 0;}
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .bio{
        width: 55%;
        padding: 4rem 15vw 4rem 15vw;
      }
      .profileImg{
        position: absolute;
        width: 600px;
        bottom: 0;
        left: 45%;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }
    .btn{
      z-index: 4;
      position: absolute;
      right: 11vw;
      top: 20rem;
    }
  }

  .sliderSelect{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 15vw;
    transform: translateY(50%);
    background-color: #2a94c3;
    border-radius: 500px;
    height: 140px;
    width: 800px;
    z-index: 4;
    .selector{
      overflow: hidden;
      height: 100%;
      width: 85%;
      .slick-list{
        height: 100%;
      }
      .slick-slider{
        height: 100%;
      }
      .slick-track{
        height: 100%;
        display: flex;
      }
      .slick-slide{
        outline: none;
        div:first-of-type{height: 100%;}
        :hover{
          .icon{top: 35%;}
          .iconName{opacity: 1;}
        }
      }
      .content{
        display: flex;
        height: 100%;
        justify-content: space-around;
        align-items: center;     
      }
      .iconWrapper{
        height: 100%;
        outline: none;
        position: relative;
        user-select: none;
        opacity: 1;
        transition: all 0.3ms;
      }
      .icon{
        width: 60%;
        cursor: pointer;
        outline: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
        z-index: 2;
      }
      .iconName{
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        opacity: 0;
        transition: all 0.3s;
        z-index: 1;
        width: 55%;
      }
    }
  }

  .slick-prev{
    width: 30px;
    height: fit-content;

    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translate(-50%, -50%);
    img{
      width: 100%;
    }
  }
  .slick-next{
    width: 30px;
    height: fit-content;

    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translate(50%, -50%);
    img{
      width: 100%;
    }
  }
  
  .btn{
    width: 3rem;
    height: 3rem;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .slick-btns{
    img:nth-of-type(1){
      position: relative;
      opacity: 1;
      transition: all 350ms ease-out !important;
    }
    img:nth-of-type(2){
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      transition: all 350ms ease-out !important;
    }
    :hover{
      img:nth-of-type(1){
        opacity: 0;
      }
      img:nth-of-type(2){
        opacity: 1;
      }
    }
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    left: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`